import React from "react"
import { Svg } from "../components/Base"

export default function Map(props) {
  return (
    <Svg
      id="e937dd5c-1a88-46eb-b274-68931cd56025"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 923.8 753.71"
      {...props}
    >
      <ellipse
        cx="1007.29"
        cy="789.73"
        rx="19.99"
        ry="48.53"
        transform="rotate(-45 849.945 919.851)"
        fill="#319795"
        opacity="0.1"
      />
      <ellipse cx="872.63" cy="705.7" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="872.63" cy="688.53" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="872.63" cy="671.36" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="872.63" cy="654.19" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="872.63" cy="637.02" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="872.63" cy="619.85" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="872.63" cy="602.68" rx="10.73" ry="14.05" fill="#3f3d56" />
      <path
        d="M1050.9,558.33a51.13,51.13,0,0,0,4-5.88l-28.2-4.63,30.5.22a51.54,51.54,0,0,0,1-40.73l-40.91,21.23L1055,500.8a51.41,51.41,0,1,0-84.9,57.53,51.16,51.16,0,0,0-5.86,9.37l36.6,19-39-13.1a51.45,51.45,0,0,0,8.29,48.27,51.4,51.4,0,1,0,80.8,0,51.41,51.41,0,0,0,0-63.56Z"
        transform="translate(-138.1 -73.15)"
        fill="#319795"
      />
      <path
        d="M959.1,590.11a51.16,51.16,0,0,0,11,31.78,51.4,51.4,0,1,0,80.8,0C1057.79,613.15,959.1,584.34,959.1,590.11Z"
        transform="translate(-138.1 -73.15)"
        opacity="0.1"
      />
      <polygon
        points="846.97 0 846.97 720 602.97 686 306.36 746.51 303.97 747 67.97 682 67.97 56 312.97 0 580.97 56 591.16 53.85 846.97 0"
        fill="#444053"
      />
      <polygon
        points="805.97 39.32 805.97 683.53 587.66 653.11 322.27 707.25 320.13 707.68 108.97 649.53 108.97 89.42 328.19 39.32 567.97 89.42 577.09 87.5 805.97 39.32"
        fill="#fff"
      />
      <polygon
        points="602.97 686 306.36 746.51 312.97 0 580.97 56 591.16 53.85 602.97 686"
        opacity="0.1"
      />
      <g
        opacity="0.3"
        fill="none"
        stroke="#444053"
        strokeMiterlimit="10"
        strokeWidth="3"
      >
        <polyline points="573.35 138 573.47 347.5 660.47 347.5 660.47 261.5 809.47 261.5 826.97 239.06" />
        <polyline points="88.97 204.5 412.47 204.5 432.47 195.5 440.47 195.5 440.47 397.5 580.47 397.5 586.47 415.5 586.47 506.5 694.47 506.5 694.47 619" />
        <line x1="483.2" y1="619" x2="483.2" y2="397.5" />
        <line x1="826.97" y1="559.66" x2="483.2" y2="559.66" />
        <line x1="740.87" y1="138" x2="740.87" y2="619" />
        <line x1="87.97" y1="517.14" x2="826.97" y2="517.14" />
        <line x1="177.91" y1="619" x2="177.91" y2="138" />
        <line x1="349.69" y1="138" x2="349.69" y2="619" />
        <line x1="88.97" y1="259.5" x2="660.47" y2="261.5" />
        <line x1="255.3" y1="138.87" x2="255.3" y2="619" />
        <polyline points="87.97 370.82 349.69 370.82 483.2 517.14" />
        <line x1="512.12" y1="138" x2="512.12" y2="397.5" />
        <line x1="483.2" y1="443.98" x2="826.97" y2="443.98" />
      </g>
      <polygon
        points="585.97 442 484.97 442 484.97 398 580.47 397.5 586.47 414.5 585.97 442"
        fill="#319795"
      />
      <path
        d="M674.07,287.15a55.28,55.28,0,0,0-55.28,55.28c0,30.53,55.28,129.72,55.28,129.72S729.35,373,729.35,342.43A55.28,55.28,0,0,0,674.07,287.15Zm0,78.86a24.32,24.32,0,1,1,24.32-24.32A24.32,24.32,0,0,1,674.07,366Z"
        transform="translate(-138.1 -73.15)"
        fill="#319795"
      />
      <path
        d="M674.07,287.15a55.28,55.28,0,0,0-55.28,55.28c0,30.53,55.28,129.72,55.28,129.72S729.35,373,729.35,342.43A55.28,55.28,0,0,0,674.07,287.15Zm0,78.86a24.32,24.32,0,1,1,24.32-24.32A24.32,24.32,0,0,1,674.07,366Z"
        transform="translate(-138.1 -73.15)"
        opacity="0.1"
      />
      <path
        d="M674.07,295.15a55.28,55.28,0,0,0-55.28,55.28c0,30.53,55.28,129.72,55.28,129.72S729.35,381,729.35,350.43A55.28,55.28,0,0,0,674.07,295.15Zm0,78.86a24.32,24.32,0,1,1,24.32-24.32A24.32,24.32,0,0,1,674.07,374Z"
        transform="translate(-138.1 -73.15)"
        fill="#319795"
      />
      <ellipse
        cx="649.86"
        cy="806.61"
        rx="33.45"
        ry="13.78"
        transform="rotate(-23.3 403.328 1104.861)"
        fill="#319795"
        opacity="0.1"
      />
      <ellipse cx="511.39" cy="727.97" rx="7.4" ry="9.68" fill="#3f3d56" />
      <ellipse cx="511.39" cy="716.13" rx="7.4" ry="9.68" fill="#3f3d56" />
      <ellipse cx="511.39" cy="704.3" rx="7.4" ry="9.68" fill="#3f3d56" />
      <ellipse cx="511.39" cy="692.47" rx="7.4" ry="9.68" fill="#3f3d56" />
      <ellipse cx="511.39" cy="680.63" rx="7.4" ry="9.68" fill="#3f3d56" />
      <ellipse cx="511.39" cy="668.8" rx="7.4" ry="9.68" fill="#3f3d56" />
      <ellipse cx="511.39" cy="656.97" rx="7.4" ry="9.68" fill="#3f3d56" />
      <path
        d="M621.8,649.13a36.12,36.12,0,0,1-2.76-4l19.44-3.2-21,.16a35.54,35.54,0,0,1-.68-28.07L645,628.6l-26-19.12a35.43,35.43,0,1,1,58.51,39.65,35.55,35.55,0,0,1,4,6.46L656.3,668.7l26.9-9a35.45,35.45,0,0,1-5.71,33.27,35.43,35.43,0,1,1-55.69,0,35.44,35.44,0,0,1,0-43.81Z"
        transform="translate(-138.1 -73.15)"
        fill="#319795"
      />
      <path
        d="M685.07,671a35.28,35.28,0,0,1-7.58,21.91,35.43,35.43,0,1,1-55.69,0C617.05,686.91,685.07,667.06,685.07,671Z"
        transform="translate(-138.1 -73.15)"
        opacity="0.1"
      />
      <ellipse
        cx="186.29"
        cy="767.73"
        rx="19.99"
        ry="48.53"
        transform="rotate(-45 28.942 897.852)"
        fill="#319795"
        opacity="0.1"
      />
      <ellipse cx="51.63" cy="683.7" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="51.63" cy="666.53" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="51.63" cy="649.36" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="51.63" cy="632.19" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="51.63" cy="615.02" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="51.63" cy="597.85" rx="10.73" ry="14.05" fill="#3f3d56" />
      <ellipse cx="51.63" cy="580.68" rx="10.73" ry="14.05" fill="#3f3d56" />
      <path
        d="M229.9,536.33a51.13,51.13,0,0,0,4-5.88l-28.2-4.63,30.5.22a51.54,51.54,0,0,0,1-40.73l-40.91,21.23L234,478.8a51.41,51.41,0,1,0-84.9,57.53,51.16,51.16,0,0,0-5.86,9.37l36.6,19-39-13.1a51.45,51.45,0,0,0,8.29,48.27,51.4,51.4,0,1,0,80.8,0,51.41,51.41,0,0,0,0-63.56Z"
        transform="translate(-138.1 -73.15)"
        fill="#319795"
      />
      <path
        d="M138.1,568.11a51.16,51.16,0,0,0,11,31.78,51.4,51.4,0,1,0,80.8,0C236.79,591.15,138.1,562.34,138.1,568.11Z"
        transform="translate(-138.1 -73.15)"
        opacity="0.1"
      />
    </Svg>
  )
}
